.wrapper {
    position: absolute;
    bottom: -7rem;
    left: 0;
    width: 100%;
    height: 2rem;
    overflow: visible;

    & > div {
        position: absolute;
        top: 0;
        border-radius: 50%;
        opacity: 0;

        & > div {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
