.statement {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70rem;
    max-width: 80%;
    transform: translate(-50%, -10rem);
    overflow: visible;
}

.statementInner {
    font-size: 3.5rem;
    color: var(--color-text);
    vertical-align: middle;
    overflow: visible;
    text-align: center;
    transform: scale(1.3);

    & > div {
        display: inline-block;
        opacity: 0;
        margin-right: 1.1rem;
        vertical-align: middle;

        b {
            font-size: 1.3em;
            font-weight: 600;
            color: var(--color-text-highlight);
        }
    }
}
